import {
  current,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import {
  CaptionShard,
} from 'shared/hooks/axon/gateway.types';

const WORD_LIMIT = 250;
interface Captions {
  value: CaptionShard[],
}

const initialState: Captions = {
  value: [],
}

type CaptionUserEditingPayload = {
  shardId: number;
  userEditing: boolean;
}

type CaptionShardWordTextPayload = {
  shardId: number,
  wordIndex: number,
  wordText: string,
}
function getWordCount(captions: CaptionShard[]){
  let wordCount = 0;
  for(let shard of captions){
    wordCount += shard?.shardWords?.length || 0;
  }
  return wordCount;
}
function copyCaptions(captions: CaptionShard[]){
  let newCaptions: CaptionShard[] = [];
  for(let caption of captions){
    if(caption){
      newCaptions[caption.shardId] = caption;
    }
  }
  return newCaptions;
}
const captionsSlice = createSlice({
  name: 'captions',
  initialState: initialState,
  reducers: {
    setCaption: (state, action: PayloadAction<CaptionShard>) => {
      let tempCaptions = copyCaptions(current(state).value);
      if (!tempCaptions?.[Number(action.payload.shardId)]?.userEditing) {
        tempCaptions[action.payload.shardId] = action.payload;
        let wordCount = getWordCount(tempCaptions);
        if(wordCount > WORD_LIMIT){
          let excessWords = wordCount - WORD_LIMIT;
          while(excessWords > 0){
            let deletedShard = tempCaptions.shift();
            let deleteShardWordCount = deletedShard?.shardWords?.length || 0;
            excessWords -= deleteShardWordCount
          }
        }
        state.value = tempCaptions;
      }
    },
    clearCaptions: (state,) => {
      state.value = [];
    },
    setCaptionUserEditing: (state, action: PayloadAction<CaptionUserEditingPayload>) => {
      if(current(state).value?.[action.payload.shardId]){
        state.value[action.payload.shardId].userEditing = action.payload.userEditing;
      }
    },
    setCaptionShardWordText: (state, action: PayloadAction<CaptionShardWordTextPayload>) => {
      if(current(state).value?.[action.payload.shardId]?.shardWords?.[action.payload.wordIndex]){
        state.value[action.payload.shardId].shardWords[action.payload.wordIndex].wordText = action.payload.wordText;
      }
    },
  }
})

export const {
  setCaption,
  clearCaptions,
  setCaptionUserEditing,
  setCaptionShardWordText,
} = captionsSlice.actions;

export default captionsSlice.reducer;